import { BaseElement, html, css } from 'Elements';

class CveScore extends BaseElement {
  static get styles() {
    return [
      css`
      `
    ];
  }

  static get properties() {
    return {
      value: { type: Number },
    };
  }

  render() {
    return html`
      <sl-badge>${this.value}</sl-badge>
    `;
  }

}

customElements.define('cve-score', CveScore);